import { ColumnMapping, ColumnMappingRequestBody, ColumnMappingTemplate } from "@speakap/types";
import { FormInstance } from "antd/lib/form/Form";
import { get, fromPairs, toPairs, some, omit, isEmpty } from "lodash";
import { notification } from "antd";
import { unflatten } from "flat";

import { createArrayField } from "./column-mappings-form-array";
import { createInputField } from "./column-mappings-form-input";
import { createObjectField } from "./column-mappings-form-object";

const SCIM_ENTERPRISE_USER_SCHEMA = "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User";
const SPEAKAP_PARAM_BAG_SCHEMA = "urn:speakap:params:scim:bag";

export const DELIMITER = "__";
export const KEY_DELIMITER = "%%";

interface FieldValidations {
    validatePrimary?: boolean;
}

export interface FieldOption {
    example?: string;
    label: string;
    selector: Array<string>;
    key: string;
    required?: boolean;
    modal?: boolean;
    validations?: FieldValidations;
}

export interface FieldComponentProps {
    form: FormInstance;
    setDirty: (value: boolean) => void;
    removeButton?: JSX.Element;
    modalButton?: (
        field: Omit<FieldOption, "key"> & { key: Array<string | number> | string },
    ) => JSX.Element;
    columnMapping?: Partial<ColumnMapping>;
}

export interface FieldComponentOption extends FieldOption {
    FieldComponent: (props: FieldComponentProps) => JSX.Element;
}

export const options: Array<FieldComponentOption> = [
    {
        func: createInputField,
        label: "External id",
        required: true,
        selector: ["template", "externalId"],
    },
    {
        func: createInputField,
        label: "Activity Control Exempt",
        selector: ["extras", "activityControlExempt"],
    },
    {
        func: createInputField,
        label: "Default role",
        modal: false,
        selector: ["extras", "defaultRole"],
    },
    {
        func: createInputField,
        label: "Login",
        selector: ["extras", "login"],
    },
    {
        func: createInputField,
        label: "Birthday",
        selector: ["extras", "birthday"],
    },
    {
        func: createInputField,
        label: "SMS",
        selector: ["extras", "sms"],
    },
    {
        func: createInputField,
        label: "Family name",
        selector: ["template", "name", "familyName"],
    },
    {
        func: createInputField,
        label: "Given name",
        selector: ["template", "name", "givenName"],
    },
    {
        func: createInputField,
        label: "Formatted name",
        selector: ["template", "name", "formatted"],
    },
    {
        func: createInputField,
        label: "Middle name",
        selector: ["template", "name", "middleName"],
    },
    {
        func: createInputField,
        label: "Honorifix prefix",
        selector: ["template", "name", "honorifixPrefix"],
    },
    {
        func: createInputField,
        label: "Honorifix suffix",
        selector: ["template", "name", "honorifixSuffix"],
    },
    {
        func: createArrayField(
            [
                {
                    example: "NL, DE",
                    key: "country",
                    label: "Country code",
                    selector: ["country"],
                },
                {
                    example: "Main Street 10, 11234",
                    key: "formatted",
                    label: "Formatted",
                    selector: ["formatted"],
                },
                {
                    example: "Amsterdam",
                    key: "locality",
                    label: "Locality",
                    selector: ["locality"],
                },
                {
                    example: "1541 JE",
                    key: "postalCode",
                    label: "Postal code",
                    selector: ["postalCode"],
                },
                {
                    example: "Noord-Holland",
                    key: "region",
                    label: "Region",
                    selector: ["region"],
                },
                {
                    example: "Raadhuisstraat 102",
                    key: "streetAddress",
                    label: "Street address",
                    selector: ["streetAddress"],
                },
                { example: '"work"', key: "type", label: "Type", selector: ["type"] },
            ],
            { selectPrimary: true },
        ),
        label: "Addresses",
        selector: ["template", "addresses"],
    },
    {
        func: createArrayField(
            [
                {
                    example: "test@test.com",
                    key: "value",
                    label: "Value",
                    required: true,
                    selector: ["value"],
                },
                {
                    example: "work",
                    key: "type",
                    label: "Type",
                    modal: false,
                    selector: ["type"],
                },
            ],
            { selectPrimary: true },
        ),
        label: "Emails",
        selector: ["template", "emails"],
        validations: {
            validatePrimary: true,
        },
    },
    {
        func: createInputField,
        label: "Active",
        selector: ["template", "active"],
    },
    {
        func: createInputField,
        label: "Display name",
        selector: ["template", "displayName"],
    },
    {
        func: createInputField,
        label: "Locale",
        selector: ["template", "locale"],
    },
    {
        func: createInputField,
        label: "Nick name",
        selector: ["template", "nickName"],
    },
    {
        func: createInputField,
        label: "Password",
        selector: ["template", "password"],
    },
    {
        func: createArrayField(
            [
                {
                    key: "value",
                    label: "Value",
                    required: true,
                    selector: ["value"],
                },
                {
                    key: "type",
                    label: "Type",
                    modal: false,
                    selector: ["type"],
                },
            ],
            { selectPrimary: true },
        ),
        label: "Phone numbers",
        selector: ["template", "phoneNumbers"],
    },
    {
        example: "en, nl-NL, nl, de-DE, nl-BE, en-GB",
        func: createInputField,
        label: "Preferred language code",
        selector: ["template", "preferredLanguage"],
    },
    {
        func: createInputField,
        label: "Title",
        selector: ["template", "title"],
    },
    {
        func: createInputField,
        label: "Timezone",
        selector: ["template", "timezone"],
    },
    {
        func: createInputField,
        label: "User type",
        selector: ["template", "userType"],
    },
    {
        func: createInputField,
        label: "Cost center",
        selector: ["template", SCIM_ENTERPRISE_USER_SCHEMA, "costCenter"],
    },
    {
        func: createInputField,
        label: "Department",
        selector: ["template", SCIM_ENTERPRISE_USER_SCHEMA, "department"],
    },
    {
        func: createInputField,
        label: "Division",
        selector: ["template", SCIM_ENTERPRISE_USER_SCHEMA, "division"],
    },
    {
        func: createInputField,
        label: "Employee number",
        selector: ["template", SCIM_ENTERPRISE_USER_SCHEMA, "employeeNumber"],
    },
    {
        func: createInputField,
        label: "Organization",
        selector: ["template", SCIM_ENTERPRISE_USER_SCHEMA, "organization"],
    },
    {
        func: createObjectField([
            {
                key: "value",
                label: "Value",
                required: true,
                selector: ["value"],
            },
            {
                key: "display",
                label: "Display",
                selector: ["display"],
            },
        ]),
        label: "Manager",
        selector: ["template", SCIM_ENTERPRISE_USER_SCHEMA, "manager"],
    },
    {
        func: createArrayField([
            {
                key: "key",
                label: "Target",
                modal: false,
                required: true,
                selector: ["key"],
            },
            {
                key: "value",
                label: "Source",
                required: true,
                selector: ["value"],
            },
        ]),
        label: "Additional fields",
        selector: ["template", SPEAKAP_PARAM_BAG_SCHEMA],
    },
].map(option => {
    /**
     * Transforming a selector to a key that is a string is a little bit complicated only because
     * there is a . (dot) in the key of the enterprise user in the SCIM type. That is why selector
     * is an array so that _.get() does not get confused. And KEY_DELIMITER is necessary so that
     * form.getFieldDecorator(key, {}) does not get confused.
     */
    const key = option.selector
        .map(selector => selector.replace(/\./g, KEY_DELIMITER))
        .join(DELIMITER);
    const fieldOption: FieldOption = {
        example: option.example,
        key,
        label: option.label,
        modal: option.modal,
        required: option.required,
        selector: option.selector,
        validations: option.validations,
    };

    return {
        ...fieldOption,
        FieldComponent: option.func(fieldOption),
    };
});

// We have some very common fields in Speakap like "role" and "login",
// that do not have any representation in our SCIM profile. In order
// to not add a SCIM bag every time we use these fields, we let the UI
// do that for us. Under the hood we are still using the SCIM bag, but
// in the UI they are rendered as there own fields and they are not part
// of the "Additional fields" anymore.
// "extras" only play a role in this file. They get populated when a
// column mapping template is parsed and removed when a column mapping
// is submitted to the api.
// If you want to add a field to "extras" edit this file in four places:
// - add it to the "options" array.
// - add it to the ColumnMappingExtras interface
// - handle it in removeExtrasFromColumnMappingTemplate()
// - handle it in extendColumnMappingTemplateWithExtras()
interface ColumnMappingExtras {
    defaultRole?: string;
    login?: string;
    birthday?: string;
    sms?: string;
    activityControlExempt?: string;
}

export const removeExtrasFromColumnMappingTemplate = (
    columnMapping?: Partial<ColumnMapping> & { extras?: ColumnMappingExtras },
): (Partial<ColumnMapping> & { extras?: ColumnMappingExtras }) | undefined => {
    if (columnMapping) {
        if (!columnMapping.template) {
            return columnMapping;
        }
        let additionalFields = columnMapping.template[SPEAKAP_PARAM_BAG_SCHEMA];
        const extras: ColumnMappingExtras = {};
        if (additionalFields) {
            additionalFields = additionalFields.filter(field => {
                if (field.key === "role") {
                    extras.defaultRole = field.value;
                    return false;
                } else if (field.key === "login") {
                    extras.login = field.value;
                    return false;
                } else if (field.key === "birthday") {
                    extras.birthday = field.value;
                    return false;
                } else if (field.key === "sms") {
                    extras.sms = field.value;
                    return false;
                } else if (field.key === "activityControlExempt") {
                    extras.activityControlExempt = field.value;
                    return false;
                }
                return true;
            });
        }
        return {
            ...columnMapping,
            extras: isEmpty(extras) ? undefined : extras,
            template:
                additionalFields && additionalFields.length === 0
                    ? omit(columnMapping.template, SPEAKAP_PARAM_BAG_SCHEMA)
                    : { ...columnMapping.template, [SPEAKAP_PARAM_BAG_SCHEMA]: additionalFields },
        };
    }
};

const extendColumnMappingTemplateWithExtras = (
    extras: ColumnMappingExtras,
    columnMappingBody: ColumnMappingRequestBody,
): ColumnMappingTemplate => {
    let additionalFields = columnMappingBody.template[SPEAKAP_PARAM_BAG_SCHEMA] || [];

    if (extras.defaultRole) {
        additionalFields = [
            ...additionalFields.filter(field => field.key !== "role"),
            {
                key: "role",
                value: extras.defaultRole,
            },
        ];
    }
    if (extras.login) {
        additionalFields = [
            ...additionalFields.filter(field => field.key !== "login"),
            {
                key: "login",
                value: extras.login,
            },
        ];
    }
    if (extras.birthday) {
        additionalFields = [
            ...additionalFields.filter(field => field.key !== "birthday"),
            {
                key: "birthday",
                value: extras.birthday,
            },
        ];
    }
    if (extras.sms) {
        additionalFields = [
            ...additionalFields.filter(field => field.key !== "sms"),
            {
                key: "sms",
                value: extras.sms,
            },
        ];
    }
    if (extras.activityControlExempt) {
        additionalFields = [
            ...additionalFields.filter(field => field.key !== "activityControlExempt"),
            {
                key: "activityControlExempt",
                value: extras.activityControlExempt,
            },
        ];
    }

    return {
        ...columnMappingBody.template,
        [SPEAKAP_PARAM_BAG_SCHEMA]: additionalFields,
    };
};

/**
 *  getFields gets a list of fields that are already part of columnMapping. If columnMapping is
 *  not set, return userName and externalId as required fields.
 */
export const getFields = (
    columnMapping?: Partial<ColumnMapping> & { extras?: ColumnMappingExtras },
): Array<string> => {
    if (!columnMapping) {
        return [["template", "externalId"].join(DELIMITER)];
    }

    return options.filter(({ selector }) => get(columnMapping, selector)).map(({ key }) => key);
};

export const buildColumnMappingBody = (
    values: Record<string, string>,
): ColumnMappingRequestBody => {
    const fixedValues = fromPairs(
        toPairs(values).map(([key, value]) => [
            key.replace(new RegExp(KEY_DELIMITER, "g"), "."),
            value,
        ]),
    );
    const {
        extras,
        ...columnMappingBody
    }: ColumnMappingRequestBody & {
        extras?: ColumnMappingExtras;
    } = unflatten(fixedValues, {
        delimiter: DELIMITER,
    });

    if (extras) {
        columnMappingBody.template = extendColumnMappingTemplateWithExtras(
            extras,
            columnMappingBody,
        );
    }

    return columnMappingBody;
};

export const validateColumnMappingBody = (columnMappingBody: ColumnMappingRequestBody): boolean => {
    for (const option of options) {
        const values = get(columnMappingBody, option.selector);
        if (values && option.validations?.validatePrimary && !some(values, { primary: true })) {
            notification.error({
                message: `Please mark one item of "${option.label}" as primary.`,
            });
            return false;
        }
    }

    return true;
};

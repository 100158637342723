import { Link } from "react-router-dom";
import React from "react";
import cx from "classnames";

import styles from "./logo.module.css";
import userSyncLogo from "./Speakap user sync - colour.png";

interface LogoProps {
    size?: "medium" | "large";
}

const Logo = ({ size = "medium" }: LogoProps): JSX.Element => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    <Link
        to="/"
        className={cx([
            styles.logo,
            {
                [styles.medium]: size === "medium",
                [styles.large]: size === "large",
            },
        ])}
    >
        <img className={styles.image} alt="Speakap logo" src={userSyncLogo} />
    </Link>
);

export default Logo;
